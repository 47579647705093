import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "classnames";
import { AuthUser } from "next-firebase-auth";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import styles from "../../assets/jss/nextjs-material-kit-pro/components/headerLinksStyle";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { UserInfo } from "../../types";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import Image from "../image";
import LanguageSwitcher from "./languageSwitcher";
import SocialMediaIconGroup from "./socialMediaIconGroup";

const useStyles = makeStyles(styles as any);
export interface Props {
  isHomepage: boolean;
  user?: AuthUser;
  userInfo?: UserInfo;
}

export default function LayoutHeader(props: Props) {
  const { isHomepage, user, userInfo } = props;
  const hasLoggedIn = !!user.id;
  const { events, bookmarks } = userInfo || {};
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);
  const [isAtTop, setIsAtTop] = React.useState<boolean>(true);

  const router = useRouter();
  const {
    i18n: { language: locale },
    t,
  } = useTranslation();

  const classes = useStyles();

  const getActionLinks = React.useCallback(
    (isMobile = false) => {
      if (!hasLoggedIn) {
        return (
          <>
            {/* <Link href="/events/submit" prefetch={false}>
              <a
                className="submit-event-link"
                data-click-label="menu_add_event"
              >
                {t("pageTitle.submitEvent")}
              </a>
            </Link> */}
            <Link href="/users/register" prefetch={false}>
              <a className="white-link" data-click-label="sign_up">
                {t("pageTitle.register")}
              </a>
            </Link>
            <Link href="/users/login" prefetch={false}>
              <a className="white-link" data-click-label="login">
                {t("pageTitle.login")}
              </a>
            </Link>
          </>
        );
      }

      if (isMobile) {
        return (
          <>
            <div className="profile-container">
              {user.photoURL && (
                <Image
                  url={user.photoURL}
                  width={36}
                  height={36}
                  alt="profile photo"
                />
              )}
              <span>{user.displayName || user.email || user.phoneNumber}</span>
            </div>
            <Link href="/events/submit" prefetch={false}>
              <a
                className="submit-event-link"
                data-click-label="menu_add_event"
              >
                {t("pageTitle.submitEvent")}
              </a>
            </Link>
            {/* <Link href="/users/bookmark" prefetch={false}>
              <a data-click-label="menu_my_bookmarks">
                {t("postloginMenu.myBookmarks")}
                {bookmarks?.length ? ` (${bookmarks.length})` : ""}
              </a>
            </Link> */}
            <Link href="/users/events" prefetch={false}>
              <a data-click-label="menu_my_event">
                {t("postloginMenu.myEvents")}
                {events?.length ? ` (${events.length})` : ""}
              </a>
            </Link>
            <Link href="/users/organizers" prefetch={false}>
              <a data-click-label="menu_organizers">
                {t("postloginMenu.changeMyOrganizers")}
              </a>
            </Link>
            <Link href="/users/profile" prefetch={false}>
              <a data-click-label="menu_setting">
                {t("postloginMenu.setting")}
              </a>
            </Link>
            {/* <a data-click-label="menu_feature_request" href="https://forms.gle/wNzcGkJjGCHs4gQt9" target="_blank">{t("pageTitle.featureRequest")}</a>
            <a data-click-label="menu_promotion_request" href="https://forms.gle/NhShbhfgY6nbwZCY6" target="_blank">{t("postloginMenu.promotionRequest")}</a>, */}
            <button
              className="logout-button"
              type="button"
              onClick={async () => {
                await user?.signOut();
                router.push("/");
              }}
              aria-label="logout"
              data-click-label="logout"
            >
              {t("postloginMenu.logout")}
            </button>
          </>
        );
      }

      return (
        <List className={classes.list + " " + classes.mlAuto}>
          <ListItem className={classes.listItem}>
            <Link href="/events/submit" prefetch={false}>
              <a
                className="submit-event-link"
                data-click-label="menu_add_event"
              >
                {t("pageTitle.submitEvent")}
              </a>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor="primary"
              buttonText={user.displayName || user.email || user.phoneNumber}
              buttonProps={{
                className: classes.navLink,
                color: "transparent",
              }}
              dropdownList={[
                // <Link href="/users/bookmark" prefetch={false}>
                //   <a
                //     className={classes.dropdownLink}
                //     data-click-label="menu_my_bookmarks"
                //   >
                //     {t("postloginMenu.myBookmarks")}
                //     {bookmarks?.length ? ` (${bookmarks.length})` : ""}
                //   </a>
                // </Link>,
                <Link href="/users/events" prefetch={false}>
                  <a
                    className={classes.dropdownLink}
                    data-click-label="menu_my_event"
                  >
                    {t("postloginMenu.myEvents")}
                    {events?.length ? ` (${events.length})` : ""}
                  </a>
                </Link>,
                <Link href="/users/organizers" prefetch={false}>
                  <a className={classes.dropdownLink} data-click-label="menu_organizers">
                    {t("postloginMenu.changeMyOrganizers")}
                  </a>
                </Link>,
                <Link href="/users/profile" prefetch={false}>
                  <a
                    className={classes.dropdownLink}
                    data-click-label="menu_setting">
                    {t("postloginMenu.setting")}
                  </a>
                </Link>,
                // <a className={classes.dropdownLink} data-click-label="menu_feature_request" href="https://forms.gle/wNzcGkJjGCHs4gQt9" target="_blank">{t("pageTitle.featureRequest")}</a>,
                // <a className={classes.dropdownLink} data-click-label="menu_promotion_request" href="https://forms.gle/NhShbhfgY6nbwZCY6" target="_blank">{t("postloginMenu.promotionRequest")}</a>,
                <button
                  className={classes.logoutButton}
                  data-click-label="logout"
                  onClick={async () => {
                    await user?.signOut();
                    router.push("/");
                  }}
                >
                  {t("postloginMenu.logout")}
                </button>,
              ]}
            />
          </ListItem>
        </List>
      );
    },
    [t, locale, user, router, classes, bookmarks?.length, events?.length]
  );

  const getPageLinks = React.useCallback(
    (isMobile?: boolean) => {
      return <></>;
    },
    [t, locale]
  );

  const toggleMobileMenu = React.useCallback(() => {
    setShowMobileMenu((val) => !val);
  }, []);

  const handleScroll = React.useCallback(() => {
    if (window) {
      if (window.scrollY > 5) {
        setIsAtTop(false);
      } else {
        setIsAtTop(true);
      }
    }
  }, []);
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { width } = useWindowDimensions();
  const isDesktop = width >= 1024;
  return (
    <header
      className={clsx(
        "layout-header",
        isHomepage && "homepage",
        isAtTop && "at-top"
      )}
    >
      <Image
        url="/images/icon.png"
        width={30}
        height={30}
        objectFit="contain"
        alt="Playwhat icon"
      />
      <Link href="/">
        <a
          style={{ display: "flex", marginLeft: "4px" }}
          data-click-label="menu_homepage"
        >
          <Image
            className="logo"
            url="/images/logo.svg"
            width={144}
            height={24}
            objectFit="contain"
            alt="Playwhat Logo"
          />
        </a>
      </Link>

      {isDesktop && (
        <>
          <nav>{getPageLinks()}</nav>
          <div className="layout-header-action">
            {getActionLinks(false)}
            <LanguageSwitcher />
            <SocialMediaIconGroup isInstagramOnly />
          </div>
        </>
      )}

      {!isDesktop && (
        <>
          <div className="hamburger-menu">
            <SocialMediaIconGroup isInstagramOnly />
            <button
              type="button"
              onClick={toggleMobileMenu}
              className="open-menu-button"
              aria-label="open side menu"
              data-click-label="menu_open_side_menu"
            >
              <Image
                className="svg-icon"
                width={24}
                height={24}
                url="/icons/menu-outline.svg"
                alt=""
              />
            </button>
          </div>
          <Drawer
            anchor="right"
            open={showMobileMenu}
            onClose={toggleMobileMenu}
          >
            <div className="mobile-menu-container">
              <button
                type="button"
                onClick={toggleMobileMenu}
                className="close-menu-button"
                aria-label="close side menu"
                data-click-label="menu_close_side_menu"
              >
                <Image
                  width={24}
                  height={24}
                  url="/icons/close-circle-outline.svg"
                  alt=""
                />
              </button>

              {getPageLinks(true)}
              {getActionLinks(true)}
              <LanguageSwitcher />
              <SocialMediaIconGroup />
            </div>
          </Drawer>
        </>
      )}
    </header>
  );
}
